 import React from "react"
import Layout from "../../../components/layout"
import { Link } from "gatsby"
import track from "../../../components/track"
import amPmDiagram from "../../../images/am-pm-infographic.png"
import tableOne from "../../../images/kodiac-04@2x.png"
import tableTwo from "../../../images/kodiac-05@2x.png"
import amPm2x from "../../../images/infographic-ampm@2x.png"

const Efficacy = () => {

  const pageTitle = 'Efficacy';
  const pageDescription = 'Explore time-to-relief data and see why MOVANTIK was used with patients who had tried laxatives unsuccessfully. Find out how long before it works.';
  const pageName = "Efficacy";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="hcp" pageSection="efficacy" pageClass="efficacy3" pageName={pageName}>
        <div className="page-title">
          <div className="container">Efficacy</div>
        </div>
        <div className="container">
          <h1>MOVANTIK 25 mg demonstrated significant improvement vs placebo.<sup>1,2,*</sup></h1>
        </div>
        <div className="headline-divider"></div>
        <div className="container">
        <h3>MOVANTIK demonstrated significant improvement in spontaneous bowel movement (SBM) response rates vs placebo across 2 pivotal trials in patients with OIC and non–cancer-related pain<sup>1,2,</sup>*<sup>,&dagger;,&Dagger;</sup></h3>
        <div className="columns two">
            <div className="column left-column">
            <img src={tableOne} className="chart" alt="chart" />
            </div>
            <div className="column right-column">
            <img src={tableTwo} className="chart" alt="chart" />
            </div>
          </div>
        <div className="end-matter">
            <div className="footnotes">
              <div className="footnote">*Response rate defined as ≥3 SBMs per week and a change from baseline of ≥1 SBM per week for at least 9 out of the 12 study weeks and 3 out of the last 4 weeks.<sup>1,2</sup></div>
              <div className="footnote asterisk"><sup>&dagger;</sup>In study KODIAC-05 the primary endpoint for MOVANTIK 12.5 mg vs. placebo was not statistically significant.</div>
              <div className="footnote asterisk"><span className="bold"><sup>&Dagger;</sup>Study Design:</span><br />
              KODIAC-04 and KODIAC-05 were identically designed 12-week, phase 3, randomized, double-blind, parallel-group, placebo-controlled studies in 1352 adult patients with chronic non-cancer pain and OIC. 652 patients and 700 patients, respectively, aged 18 to 84 years, were randomized to receive MOVANTIK 12.5 mg, MOVANTIK 25 mg, or placebo once daily for 12 weeks.<sup>1,2</sup></div>
            </div>
          </div>
        </div>
        <div class="grey-block">
        <div className="container">
          <h1>Rapid response with MOVANTIK for same-day relief<sup>1,2</sup></h1>
        </div>
        <div className="container">
          <div className="columns two">
            <div className="column left-column">
            <h3>Time to first post-dose SBM was significantly shorter with MOVANTIK vs placebo<sup>1</sup></h3>
            <ul>
              <li>Median time to first post-dose SBM was 6-12 hours for MOVANTIK 25 mg vs 36-37 hours for placebo<sup>&sect;</sup></li>
            </ul>
            
            </div>
            <div className="column right-column">
              <h3>Most patients had a post-dose SBM within 24 hours for MOVANTIK vs placebo<sup>1</sup></h3>
              <ul>
                <li>Across two studies, the percentage of patients having a post-dose SBM within 24 hours was 61-70% for MOVANTIK 25 mg and 58% for MOVANTIK 12.5 mg<sup>&sect;</sup></li>
              </ul>
              {/* <Link to="/hcp/contact/" id="hcp-efficacy3-btn-request" className="button" onClick={() => track("HCP AM to PM Efficacy page", "button click", "HCP Efficacy-Get in touch Button")}>
                <div className="btn-text">Curious about MOVANTIK?<br /><span className="bold">Contact us</span></div>
                <div className="btn-arrow"></div>
              </Link> */}
            </div>
          </div>
          <div className="center infographic">
          <img src={amPm2x} alt="MOVANTIK in the AM may lead to Bowel movement in the PM" />
          {/* <img src={amPmDiagram} className="chart" alt="chart" /> */}
          </div>
          <div className="end-matter">
            <div className="footnotes">
              <div className="footnote"><sup>&sect;</sup>These analyses do not include the results for MOVANTIK 12.5 mg versus placebo in Study KODIAC-05 because the primary endpoint was not statistically significant.<sup>1</sup></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="center">
          <Link to="/hcp/safety/" id="hcp-efficacy-btn-request" className="button" onClick={() => track("HCP Efficacy page", "button click", "HCP Efficacy-See tolerability Button")}>
            <div className="btn-text">Patients were taking a variety of opioids while on MOVANTIK.<sup>1</sup><br /><span className="bold">See tolerability and safety data</span></div>
            <div className="btn-arrow"></div>
          </Link>
        </div>
      <div className="end-matter">
            <div className="footnotes">
      <div className="references"><span className="bold">References: 1.</span> MOVANTIK<sup>®</sup>  (naloxegol). Prescribing information. Valinor Pharma, LLC; 2023. <span className="bold">2.</span> Chey WD, Webster L, Sostek M, Lappalainen J, Barker PN, Tack J. Naloxegol for opioid-induced constipation in patients with noncancer pain. <em>N Engl J Med.</em> 2014;370(25):2387-2396.</div>
      </div></div>
      </div>
    </Layout>
  )
}

export default Efficacy
